// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-de-privacidad-tsx": () => import("./../../../src/pages/aviso-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-tsx" */),
  "component---src-pages-contrato-credito-tsx": () => import("./../../../src/pages/contrato-credito.tsx" /* webpackChunkName: "component---src-pages-contrato-credito-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-soporte-tsx": () => import("./../../../src/pages/soporte.tsx" /* webpackChunkName: "component---src-pages-soporte-tsx" */),
  "component---src-pages-tarjetas-tsx": () => import("./../../../src/pages/tarjetas.tsx" /* webpackChunkName: "component---src-pages-tarjetas-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */),
  "component---src-pages-travesia-tsx": () => import("./../../../src/pages/travesia.tsx" /* webpackChunkName: "component---src-pages-travesia-tsx" */),
  "component---src-templates-terms-template-index-tsx": () => import("./../../../src/templates/TermsTemplate/index.tsx" /* webpackChunkName: "component---src-templates-terms-template-index-tsx" */)
}

